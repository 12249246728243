<template>
  <div class="ProjectPage">
    <div style="height:1px" />
    <div class="top_img" @click="abuyy()">
      <img src="@/assets/img/activeEleven/09.png" alt="">
    </div>
    <div class="til_top1">
      <img src="@/assets/img/activeEleven/11.png" alt="">
      <div class="til_top1_bottom">
        <img src="@/assets/img/activeEleven/08.png" alt="">
      </div>
    </div>
    <div class="main_top1">
      <div class="main_top1_bac">
        <div class="main_top1_item">
          <div class="main_top1_item_til">
            <img src="@/assets/img/activeEleven/24.png" alt="">
          </div>
          <div class="main_top1_item_btm ">
            <div class="main_top1_item_btm_til">自研课程系统</div>
            <div class="main_top1_item_btm_til1">精讲课程 提炼考点 </div>
            <div class="main_top1_item_btm_til1">直播录播 高效学习</div>
          </div>
        </div>
        <div class="main_top1_item">
          <div class="main_top1_item_til">
            <img src="@/assets/img/activeEleven/25.png" alt="">
          </div>
          <div class="main_top1_item_btm i1">
            <div class="main_top1_item_btm_til">多层次课程体系</div>
            <div class="main_top1_item_btm_til1">精讲核心知识 强化巩固</div>
            <div class="main_top1_item_btm_til1">高频考点串讲 重点讲解</div>
          </div>
        </div>
        <div class="main_top1_item">
          <div class="main_top1_item_til">
            <img src="@/assets/img/activeEleven/26.png" alt="">
          </div>
          <div class="main_top1_item_btm">

            <div class="main_top1_item_btm_til">专属督导服务</div>
            <div class="main_top1_item_btm_til1">个人基础 定制学习督学方案</div>
            <div class="main_top1_item_btm_til1">实时答疑 及时解决学习难题</div>
          </div>
        </div>
        <div class="main_top1_item">
          <div class="main_top1_item_til">
            <img src="@/assets/img/activeEleven/12.png" alt="">
          </div>
          <div class="main_top1_item_btm i1">

            <div class="main_top1_item_btm_til">多端口学习入口</div>
            <div class="main_top1_item_btm_til1">多端听课 线上课程</div>
            <div class="main_top1_item_btm_til1">随时随地 轻松学习</div>
          </div>
        </div>
        <div class="main_top1_item">
          <div class="main_top1_item_til">
            <img src="@/assets/img/activeEleven/13.png" alt="">
          </div>
          <div class="main_top1_item_btm">

            <div class="main_top1_item_btm_til">专属教辅资料包</div>
            <div class="main_top1_item_btm_til1">资深老师精编 高效学习</div>
            <div class="main_top1_item_btm_til1">自研纸质版教辅 免费赠送</div>
          </div>
        </div>
        <div class="main_top1_item">
          <div class="main_top1_item_til">
            <img src="@/assets/img/activeEleven/14.png" alt="">
          </div>
          <div class="main_top1_item_btm i1">
            <div class="main_top1_item_btm_til">班主任全程跟踪护航</div>
            <div class="main_top1_item_btm_til1">定时班会 学习计划分解</div>
            <div class="main_top1_item_btm_til1">人工批改 聚焦每位学员</div>
          </div>
        </div>
      </div>
    </div>
    <div class="til_top1 til_top2">
      <img src="@/assets/img/activeEleven/15.png" alt="">
      <div class="til_top1_bottom">
        <img src="@/assets/img/activeEleven/05.png" alt="">
      </div>
    </div>
    <div class="main_top1 main_top2">
      <div class="main_top1_bac2">
        <div class="main_top1_bac2_top">
          <img src="@/assets/img/activeEleven/28.png" alt="">
        </div>
        <div class="main_top1_bac2_mid">
          即日起，购买会计学协议班、汉语言文学协议班、工商管理协议班、学前教育协议班、行政管理协议班、工程管理协议班，
          <span> 免费赠送统招专升本，具体可咨询课程老师。</span>
        </div>
        <div class="main_top1_bac2_btm" @click="terShow()">
          <img src="@/assets/img/activeEleven/27.png" alt="" @click="terShow()">
        </div>
      </div>
    </div>
    <div class="til_top1 til_top3">
      <img src="@/assets/img/activeEleven/16.png" alt="">
      <div class="til_top1_bottom">
        <img src="@/assets/img/activeEleven/06.png" alt="">
      </div>
    </div>
    <div class="main_top1 main_top3">
      <div class="main_top1_bac2">
        <div class="main_top1_bac2_item">
          <div class="main_top1_bac2_item_banner">
            <img src="@/assets/img/activeEleven/17.png" alt="">
          </div>
          <div class="main_top1_bac2_item_til">高等教育自学考试-会计学（本科）
            -协议班</div>
          <div class="main_top1_bac2_item_vip">#专属服务·极速管疑·督学跟踪 <span> ▏16门课程</span></div>
          <div class="main_top1_bac2_item_price">
            <div class="price">￥9800</div>
            <div class="btn" @click="toCoupon(1)">查看详情</div>
          </div>
        </div>
        <div class="main_top1_bac2_item">
          <div class="main_top1_bac2_item_banner">
            <img src="@/assets/img/activeEleven/18.png" alt="">
          </div>
          <div class="main_top1_bac2_item_til">高等教育自学考试-汉语言（本科）
            -协议班</div>
          <div class="main_top1_bac2_item_vip">#专属服务·极速管疑·督学跟踪 <span> ▏15门课程</span></div>
          <div class="main_top1_bac2_item_price">
            <div class="price">￥9800</div>
            <div class="btn" @click="toCoupon(2)">查看详情</div>
          </div>
        </div>
        <div class="main_top1_bac2_item">
          <div class="main_top1_bac2_item_banner">
            <img src="@/assets/img/activeEleven/19.png" alt="">
          </div>
          <div class="main_top1_bac2_item_til">高等教育自学考试-工商管理（本科）-协议班</div>
          <div class="main_top1_bac2_item_vip">#专属服务·极速管疑·督学跟踪 <span> ▏15门课程</span></div>
          <div class="main_top1_bac2_item_price">
            <div class="price">￥9800</div>
            <div class="btn" @click="toCoupon(3)">查看详情</div>
          </div>
        </div>
        <div class="main_top1_bac2_item">
          <div class="main_top1_bac2_item_banner">
            <img src="@/assets/img/activeEleven/20.png" alt="">
          </div>
          <div class="main_top1_bac2_item_til">高等教育自学考试-学前教育（本科）-协议班</div>
          <div class="main_top1_bac2_item_vip">#专属服务·极速管疑·督学跟踪 <span> ▏13门课程</span></div>
          <div class="main_top1_bac2_item_price">
            <div class="price">￥9800</div>
            <div class="btn" @click="toCoupon(4)">查看详情</div>
          </div>
        </div>
        <div class="main_top1_bac2_item">
          <div class="main_top1_bac2_item_banner">
            <img src="@/assets/img/activeEleven/21.png" alt="">
          </div>
          <div class="main_top1_bac2_item_til">高等教育自学考试-行政管理（本科）-协议班</div>
          <div class="main_top1_bac2_item_vip">#专属服务·极速管疑·督学跟踪 <span> ▏18门课程</span></div>
          <div class="main_top1_bac2_item_price">
            <div class="price">￥9800</div>
            <div class="btn" @click="toCoupon(5)">查看详情</div>
          </div>
        </div>
        <div class="main_top1_bac2_item">
          <div class="main_top1_bac2_item_banner">
            <img src="@/assets/img/activeEleven/22.png" alt="">
          </div>
          <div class="main_top1_bac2_item_til">高等教育自学考试-工程管理（本科）-协议班</div>
          <div class="main_top1_bac2_item_vip">#专属服务·极速管疑·督学跟踪 <span> ▏ 4门课程</span></div>
          <div class="main_top1_bac2_item_price">
            <div class="price">￥9800</div>
            <div class="btn" @click="toCoupon(6)">查看详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="til_top1 til_top4">
      <img src="@/assets/img/activeEleven/23.png" alt="">
      <div class="til_top1_bottom">
        <img src="@/assets/img/activeEleven/07.png" alt="">
      </div>
    </div>
    <div class="main_top1 main_top4">
      <div class="main_top1_bac2">
        <div class="main_top1_bac2_item b0">
          <div class="main_top1_bac2_item_til">知识点精讲阶段</div>
          <div class="main_top1_bac2_item_tils">系统讲解教材考点 </div>
          <div class="main_top1_bac2_item_tils">全面夯实考生基础</div>
          <div class="bottom_img">
            <img src="@/assets/img/activeEleven/02.png" alt="">
          </div>
        </div>
        <div class="main_top1_bac2_item b1">
          <div class="main_top1_bac2_item_til c1">考点串讲阶段</div>
          <div class="main_top1_bac2_item_tils">教材核心进行串讲 </div>
          <div class="main_top1_bac2_item_tils">透彻掌握基础知识</div>
          <div style="width:87px;height: 93px;" class="bottom_img">
            <img src="@/assets/img/activeEleven/03.png" alt="">
          </div>
        </div>
        <div class="main_top1_bac2_item b2">
          <div class="main_top1_bac2_item_til c2">题型专项训练</div>
          <div class="main_top1_bac2_item_tils">客观题题库 </div>
          <div class="main_top1_bac2_item_tils">易错题题库</div>
          <div class="main_top1_bac2_item_tils">个人错题库</div>
          <div style="width:87px;height: 93px;" class="bottom_img">
            <img src="@/assets/img/activeEleven/04.png" alt="">
          </div>
        </div>
        <div class="main_top1_bac2_item b3">
          <div class="main_top1_bac2_item_til c3">全真模考讲练</div>
          <div class="main_top1_bac2_item_tils">周练月测 结合巩固 </div>
          <div class="main_top1_bac2_item_tils">模拟冲刺 强化心态</div>
          <div class="bottom_img">
            <img src="@/assets/img/activeEleven/01.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShowRegin" class="pageAll" @click="isShowRegin = false">
      <div class="upDio">
        <img src="@/assets/img/activeEleven/2.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      isShowRegin: false
    }
  },
  methods: {
    terShow() {
      this.isShowRegin = true
    },
    toCoupon(val) {
      if (val == 1) {
        window.open(
          'http://www.juxueu.com/VIP/VIPClassxq?id=3144',
          '_blank'
        )
      } else if (val == 2) {
        window.open(
          'http://www.juxueu.com/VIP/VIPClassxq?id=3148',
          '_blank'
        )
      } else if (val == 3) {
        window.open(
          'http://www.juxueu.com/VIP/VIPClassxq?id=3146',
          '_blank'
        )
      } else if (val == 4) {
        window.open(
          'http://www.juxueu.com/VIP/VIPClassxq?id=3147',
          '_blank'
        )
      } else if (val == 5) {
        window.open(
          'http://www.juxueu.com/VIP/VIPClassxq?id=3143',
          '_blank'
        )
      } else if (val == 6) {
        window.open(
          'http://www.juxueu.com/VIP/VIPClassxq?id=3145',
          '_blank'
        )
      }
    }
  }
}
</script>
<!-- <script>

var _hmt = _hmt || [];
(function () {
  var hm = document.createElement('script')
  hm.src = 'https://hm.baidu.com/hm.js?b74e75c99505658933d94d7850453fc5'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()

 </script>  -->
<style lang="less" scoped>
.ProjectPage {
  width: 100%;
  height: 5000px;
  position: relative;
  background-image: url("~@/assets/img/activeEleven/10.png");
  background-size: cover;

  .top_img {
    width: 799px;
    height: 608px;
    margin: 0 auto;
    margin-top: 98px;
    perspective: 1000px;

    img {
      width: 100%;
      height: 100%;
      animation: rotate 2s;
    }
  }

  .til_top1 {
    width: 1098px;
    height: 399px;
    margin: 0 auto;

    position: relative;
    z-index: 9;

    img {
      width: 100%;
      height: 100%;
    }

    .til_top1_bottom {
      position: absolute;
      bottom: -50px;
      width: 497px;
      height: 93px;
      left: calc(50% - 250px);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .til_top2 {
    position: relative;
    top: -100px;
    margin-top: 0;
  }

  .til_top3 {
    position: relative;
    top: -240px;
    margin-top: 0;
  }

  .til_top4 {
    position: relative;
    top: -400px;
    margin-top: 0;
  }

  .main_top1 {
    width: 1201px;
    height: 840px;
    background: linear-gradient(0deg, #5c64ea, #c0d3ff);
    border-radius: 20px;
    margin: 0 auto;
    padding-top: 2px;

    .main_top1_bac {
      width: 1198px;
      height: 836px;
      background: linear-gradient(0deg, #3419ff, #695bfe);
      border-radius: 20px;
      margin: 0px auto;
      display: flex;
      flex-wrap: wrap;
      padding: 35px 35px;
      justify-content: space-between;
      align-items: space-around;
      padding-top: 100px;

      .main_top1_item {
        width: 368px;
        height: 313px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .main_top1_item_til {
          width: 127px;
          height: 127px;
          z-index: 99;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .main_top1_item_btm {
          width: 368px;
          height: 243px;
          background: #ffffff;
          box-shadow: 0px 7px 0px 0px #8494ff;
          border-radius: 50px;
          position: relative;
          top: -66px;
          padding-top: 74px;
          flex-shrink: 0;

          .main_top1_item_btm_til {
            font-size: 35px;
            font-family: SourceHanSansSC;
            font-weight: 600;
            color: #3034c9;
            text-align: center;
            margin-bottom: 22px;
          }

          .main_top1_item_btm_til1 {
            font-size: 24px;
            font-family: SourceHanSansSC;
            font-weight: 500;
            color: #221815;
            line-height: 40px;
            text-align: center;
          }
        }

        .i1 {
          box-shadow: 0px 10px 0px 0px #ffb400,
            1px 5px 7px 0px rgba(234, 130, 67, 0.44);

          .main_top1_item_btm_til {
            color: #ff7f00;
          }
        }
      }
    }
  }

  .main_top2 {
    width: 1214px;
    height: 832px;
    background: linear-gradient(0deg, #5c64ea, #c0d3ff);
    border-radius: 20px;
    margin: 0 auto;
    padding-top: 4px;
    position: relative;
    top: -100px;

    .main_top1_bac2 {
      width: 1210px;
      height: 828px;
      background: linear-gradient(0deg, #3419ff, #695bfe);
      border-radius: 20px;
      margin: 0 auto;
      padding: 50px 45px;

      .main_top1_bac2_top {
        width: 1129px;
        height: 395px;

        img {
          width: 100%;
          height: 100%;
          margin-top: 20px;
        }
      }

      .main_top1_bac2_mid {
        width: 1120px;
        border: 3px solid #ffffff;
        border-radius: 20px;
        padding: 29px 32px;
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 46px;
        margin-top: 22px;

        span {
          color: #fff600;
          font-weight: 600;
        }
      }

      .main_top1_bac2_btm {
        width: 635px;
        height: 77px;
        margin: 0 auto;
        margin-top: 40px;
        cursor: pointer;
        z-index: 99;
        position: relative;
      }
    }
  }

  .main_top3 {
    width: 1214px;
    height: 1001px;
    background: linear-gradient(0deg, #5c64ea, #c0d3ff);
    border-radius: 20px;
    margin: 0 auto;
    padding-top: 4px;
    position: relative;
    top: -240px;

    .main_top1_bac2 {
      width: 1210px;
      height: 996px;
      background: linear-gradient(0deg, #3419ff, #695bfe);
      border-radius: 20px;
      margin: 0 auto;
      padding: 50px 26px;
      position: relative;
      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .main_top1_bac2_item {
        width: 370px;
        height: 416px;
        background: #ffffff;
        border-radius: 10px;
        padding: 18px;

        .main_top1_bac2_item_banner {
          width: 332px;
          height: 187px;
        }

        .main_top1_bac2_item_til {
          width: 307px;
          height: 56px;
          font-size: 21px;
          font-family: SourceHanSansSC;
          font-weight: 600;
          color: #313030;
          line-height: 36px;
          margin: 0 auto;
          margin-top: 18px;
        }

        .main_top1_bac2_item_vip {
          width: 307px;
          height: 17px;
          font-size: 16px;
          font-family: SourceHanSansSC;
          font-weight: 400;
          color: #c96d00;
          line-height: 36px;
          margin: 0 auto;
          margin-top: 20px;

          span {
            color: #595757;
            margin-left: 5px;
          }
        }

        .main_top1_bac2_item_price {
          width: 307px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          margin-top: 35px;

          .price {
            font-size: 34px;
            font-family: SourceHanSansSC;
            font-weight: 400;
            color: #ff3000;
            line-height: 36px;
            margin-left: -6px;
          }

          .btn {
            width: 123px;
            height: 36px;
            background: #ff3000;
            border-radius: 18px;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
            font-size: 18px;
            font-family: SourceHanSansSC;
            font-weight: 400;
            color: #ffffff;
            position: relative;
            z-index: 99;
          }
        }
      }
    }
  }

  .main_top4 {
    width: 1214px;
    height: 362px;
    background: linear-gradient(0deg, #5c64ea, #c0d3ff);
    border-radius: 20px;
    margin: 0 auto;
    padding-top: 4px;
    position: relative;
    top: -400px;

    .main_top1_bac2 {
      width: 1210px;
      height: 358px;
      background: linear-gradient(0deg, #3419ff, #695bfe);
      border-radius: 20px;
      margin: 0 auto;
      padding: 30px 18px;
      position: relative;
      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .main_top1_bac2_item {
        width: 282px;
        height: 204px;
        border-radius: 20px;
        position: relative;
        padding: 39px 17px;
        z-index: 999;

        .main_top1_bac2_item_til {
          font-size: 28px;
          font-family: SourceHanSansSC;
          font-weight: bold;
          color: #ff6301;
        }

        .main_top1_bac2_item_tils {
          font-size: 22px;
          font-family: SourceHanSansSC;
          font-weight: 400;
          color: #161616;
          margin-top: 7px;
        }

        .bottom_img {
          position: absolute;
          bottom: 6px;
          right: 7px;
          width: 82px;
          height: 70px;
        }

        .c1 {
          color: #2c17ff;
        }

        .c2 {
          color: #0091e7;
        }

        .c3 {
          color: #35d310;
        }
      }

      .b0 {
        background: linear-gradient(139deg, #ffffff, #ffe4d3);
      }

      .b1 {
        background: linear-gradient(140deg, #ffffff, #e1deff);
      }

      .b2 {
        background: linear-gradient(139deg, #ffffff, #c9ebff);
      }

      .b3 {
        background: linear-gradient(139deg, #ffffff, #d9ffd0);
      }
    }
  }
}

img {
  width: 100%;
  height: 100%;
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg) translateZ(-100px);
  }

  100% {
    transform: rotateY(360deg) translateZ(0px);
  }
}

.pageAll {
  width: 100%;
  position: fixed;
  top: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  // opacity: 0.5;
}

.upDio {
  position: fixed;
  width: 377px;
  // height: 580rpx;
  // background: #ffffff;
  box-shadow: 0rpx 20px 40px 0px rgba(28, 23, 47, 0.2);
  border-radius: 28px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 100%;
  }
}
</style>
